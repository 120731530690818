<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('users.user_add')}}</h1>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            <mdb-row>
              <mdb-col>
                <mdb-card>
                  <mdb-card-header color="blue darken-3">Informations</mdb-card-header>
                  <mdb-card-body>
                    <mdb-input :label="$t('users.name')" type="text" v-model="userdetails.name" />
                    <mdb-input :label="$t('users.email')" type="text" v-model="userdetails.email" />
                    <mdb-select
                      color="primary"
                      v-model="accesslevels"
                      :label="$t('users.accesslevel')"
                      v-on:getValue="getSelectedValue"
                      :visibleOptions=10
                    />
                    <mdb-select
                      color="primary"
                      v-model="langs"
                      :label="$t('users.lang_welcome_email')"
                      v-on:getValue="getSelectedValueLang"
                      :visibleOptions=2
                    />
                    <mdb-btn color="elegant" type="button" class="btn-block z-depth-2" v-on:click="saveuser()">{{$t('users.add')}}</mdb-btn>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbRow,
  mdbCol,
  mdbSelect,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'userAdd',
  data() {
    return {
      error: null,
      success: null,
      userdetails: {
        name: '',
        email: '',
        accesslevel: '',
        lang: 'fr',
      },
      confirm: false,
      accesslogs: [],
      accesslevels: [
        { text: 'FULLACCESS', value: 'FULLACCESS', selected: false },
        { text: 'HOLASUN', value: 'HOLASUN', selected: false },
        { text: 'AGENT', value: 'AGENT', selected: false },
        { text: 'GATEWAYAGENT', value: 'GATEWAYAGENT', selected: false },
      ],
      langs: [
        { text: 'Français', value: 'fr', selected: true },
        { text: 'English', value: 'en', selected: false },
      ],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbRow,
    mdbCol,
    mdbSelect,
  },
  methods: {
    saveuser() {
      const self = this;
      const api = new Apicall();
      this.error = null;
      this.success = null;
      api.call('POST', 'api/user/add', { userdetails: self.userdetails }).then((response) => {
        if (response.status === 'OK') {
          self.success = response.msg;
        } else {
          self.error = response.msg;
        }
      });
    },
    getSelectedValue(value) {
      this.userdetails.accesslevel = value;
    },
    getSelectedValueLang(value) {
      this.userdetails.lang = value;
    },
  },
};
</script>
